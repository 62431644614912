import React from 'react';

import { liz } from '../../theme';
import { Stack } from '../../layout';

const Form = ({ children, autoLayout = true, handleSubmit, ...props }) => {
  const submit = e => {
    e.preventDefault();
    handleSubmit(e);
  };

  return (
    <form {...props} onSubmit={submit}>
      {autoLayout ? <Stack spacing={liz.spacing(2)}>{children}</Stack> : children}
    </form>
  );
};

export { Form };
