import React from 'react';
import { css } from 'styled-components';

// import { NonNativeDatePicker } from './NonNativeDatePicker';
// import { useKeyPress, browserSupportsDateInput } from '../../';

import {
  OutlinedInput,
  OutlinedInputWrapper,
  UnderlinedInputWrapper,
  UnderlinedInput,
  InvisibleInput,
  InvisibleInputWrapper,
} from './TextInput.StyledComponents';
import { liz } from '../../theme';
import { Tiny } from '../../../../components/text'; // TODO: Copy over text from main app

// TODO: keeping the prop interface unchanged for legacy purposes
// now that we're extending HTMLInputElement we can actually just
// pass type="date" as a prop

// type TextInputVariant = 'outlined' | 'underlined' | 'invisible';

// export type TextInputProps = $Rest<HTMLInputElement, {}> & {
//   password?: boolean,
//   date?: boolean,
//   number?: boolean,
//   email?: boolean,
//   readOnly?: boolean,
//   extraClasses?: string, // TODO: Convert to styled components
//   onChange: (value: string) => void,
//   pillCapLabel?: string,
//   fat?: boolean,
//   fontSize?: $PropertyType<CSSStyleDeclaration, 'fontSize'>,
//   fontColor?: $PropertyType<CSSStyleDeclaration, 'color'>,
//   backgroundColor?: $PropertyType<CSSStyleDeclaration, 'backgroundColor'>,
//   valueSuffix?: string,
//   variant?: TextInputVariant,
//   Adornment?: React.Element<any>,
// };

const TextInput = React.forwardRef/*<TextInputProps, HTMLInputElement>*/((props, inputRef) => {
  const {
    onChange,
    placeholder,
    value,
    type,
    readOnly = false,
    password = false,
    number = false,
    email = false,
    date = false,
    extraClasses,
    pillCapLabel,
    valueSuffix,
    variant = 'outlined',
    Adornment,
    ...restProps
  } = props;

  const [inputFocused, setInputFocused] = React.useState(false);

  const inputOnChange = value => onChange(value);

  const onInputFocus = () => setInputFocused(true);

  const onInputBlur = () => {
    setInputFocused(false);
    props.onblur && props.onblur();
  };

  let inputType = type ? type : 'text';
  if (password) inputType = 'password';
  if (date) inputType = 'date';
  if (number) inputType = 'number';
  if (email) inputType = 'email';

  // if (date && !browserSupportsDateInput()) {
  //   return <NonNativeDatePicker onChange={inputOnChange} />;
  // }

  if (variant === 'outlined') {
    return (
      <OutlinedInputWrapper focused={inputFocused}>
        <OutlinedInput
          {...restProps}
          ref={inputRef}
          readonly={readOnly} // HTML attribute
          readOnly={readOnly} // internal prop
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          hasPillCapLabel={!!pillCapLabel}
          hasValueSuffix={!!valueSuffix}
          onChange={e => inputOnChange(e.target.value)}
          placeholder={placeholder}
          value={value}
          type={inputType}
          css={{ background: valueSuffix ? 'none' : null }}
        />
        {pillCapLabel && <Tiny>{pillCapLabel}</Tiny>}
        {valueSuffix && !pillCapLabel ? (
          <OutlinedInput
            value={`${value ? value : ''}${valueSuffix}`}
            css={css`
              position: absolute;
              color: ${({ theme }) => theme.color.grey50};
              z-index: -1;
              border-width: ${inputFocused ? '2px' : null};
            `}
            fontSize={restProps.fontSize}
            fat={restProps.fat}
            tabIndex={-1} // prevent being tabbed to
          />
        ) : null}
        {Adornment ? (
          <span
            css={{
              right: '0',
              height: '100%',
              display: 'inline-flex',
              position: 'absolute',
              alignItems: 'center',
            }}>
            {React.cloneElement(Adornment, { as: 'span' })}
          </span>
        ) : null}
      </OutlinedInputWrapper>
    );
  }

  // Currently, invisible variant does not support valueSuffix, pillCapLabel, or Adornment
  // Currently, invisible variant does not look different when in readonly mode
  if (variant === 'invisible') {
    return (
      <InvisibleInputWrapper focused={inputFocused}>
        <InvisibleInput
          {...restProps}
          ref={inputRef}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          onChange={e => inputOnChange(e.target.value)}
          placeholder={placeholder}
          value={value}
          type={inputType}
        />
      </InvisibleInputWrapper>
    );
  }

  // Currently, underlined variant does not support valueSuffix or Adornment
  // Currently, underlined variant does not look different when in readonly mode
  return (
    <UnderlinedInputWrapper focused={inputFocused}>
      <UnderlinedInput
        {...restProps}
        ref={inputRef}
        onFocus={onInputFocus}
        onBlur={onInputBlur}
        onChange={e => inputOnChange(e.target.value)}
        placeholder={placeholder}
        value={value}
        type={inputType}
      />
      {pillCapLabel && <Tiny>{pillCapLabel}</Tiny>}
    </UnderlinedInputWrapper>
  );
});

export { TextInput };
