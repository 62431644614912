import React from 'react';
import styled from 'styled-components';

import { Frame } from '../LandingPageV2/LandingPage.StyledComponents';
import { Button } from '../Button';
import { TextInput } from '../../packages/NamebaseUi/Form';
import { Tiny, Small } from '../text';
import backgroundImage1x from '../../assets/images/register-background-1x.png';
import backgroundImage2x from '../../assets/images/register-background-2x.png';

export const RegisterFormSizeLayout = styled.div`
  width: ${({ theme }) => theme.spacing(37.5)};
  margin-top: ${({ theme }) => theme.spacing(5)};
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${({ theme }) => theme.spacing(8)};

  @media ${({ theme }) => theme.breakpoints.sm} {
    width: ${({ theme }) => theme.spacing(65)};
    max-width: 100%;
  }
`;

export const RegisterFormFixedSizeLayout = styled(RegisterFormSizeLayout)`
  @media ${({ theme }) => theme.breakpoints.sm} {
    width: ${({ theme }) => theme.spacing(37.5)};
  }
`;

export const GithubButton = styled(Button).attrs(() => ({
  as: 'a',
  size: 'large',
  children: (
    <React.Fragment>
      <svg
        width="1024"
        height="1024"
        viewBox="0 0 1024 1024"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 0C3.58 0 0 3.58 0 8C0 11.54 2.29 14.53 5.47 15.59C5.87 15.66 6.02 15.42 6.02 15.21C6.02 15.02 6.01 14.39 6.01 13.72C4 14.09 3.48 13.23 3.32 12.78C3.23 12.55 2.84 11.84 2.5 11.65C2.22 11.5 1.82 11.13 2.49 11.12C3.12 11.11 3.57 11.7 3.72 11.94C4.44 13.15 5.59 12.81 6.05 12.6C6.12 12.08 6.33 11.73 6.56 11.53C4.78 11.33 2.92 10.64 2.92 7.58C2.92 6.71 3.23 5.99 3.74 5.43C3.66 5.23 3.38 4.41 3.82 3.31C3.82 3.31 4.49 3.1 6.02 4.13C6.66 3.95 7.34 3.86 8.02 3.86C8.7 3.86 9.38 3.95 10.02 4.13C11.55 3.09 12.22 3.31 12.22 3.31C12.66 4.41 12.38 5.23 12.3 5.43C12.81 5.99 13.12 6.7 13.12 7.58C13.12 10.65 11.25 11.33 9.47 11.53C9.76 11.78 10.01 12.26 10.01 13.01C10.01 14.08 10 14.94 10 15.21C10 15.42 10.15 15.67 10.55 15.59C13.71 14.53 16 11.53 16 8C16 3.58 12.42 0 8 0Z"
          transform="scale(64)"
        />
      </svg>
      Continue with Github
    </React.Fragment>
  ),
}))`
  svg {
    height: ${({ theme }) => theme.spacing(3)};
    width: ${({ theme }) => theme.spacing(3)};
    margin-right: ${({ theme }) => theme.spacing(1)};
  }

  path {
    fill: ${({ theme }) => theme.color.white};
  }
`;

export const RegisterTextInput = styled(TextInput)`
  font-size: ${({ theme }) => theme.text.small.size};
  line-height: ${({ theme }) => theme.text.small.lineHeight};
  font-family: 'Roboto Variable';

  @media ${({ theme }) => theme.breakpoints.sm} {
    font-size: 16.5px;
    font-variation-settings: 'wght' 350;
    -webkit-appearance: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.color.grey50};
  }
`;

export const TermsOfServiceText = styled(Tiny).attrs(props => ({
  colored: 'grey70',
}))`
  margin-top: ${({ theme }) => theme.spacing(1.5)};

  a {
    text-decoration: underline;
  }
  a:hover {
    color: ${({ theme }) => theme.color.grey90};
  }
`;

export const ReferralWelcome = styled(Small).attrs(props => ({
  align: 'center',
  colored: 'white',
}))`
  top: 0;
  width: 100vw;
  z-index: 2;
  position: fixed;
  padding: 20px 16px;
  background: ${({ theme }) => theme.color.namebaseGreen};
`;

export const DashedLine = styled.div`
  height: 50%;
  flex: 1;
  border-bottom: ${({ theme }) => `1px dashed ${theme.color.grey60}`};
`;

export const Divider = styled.div`
  display: flex;
  height: max-content;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

export const PageBackgroundImage = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: -1;
  position: fixed;
  background-image: url(${backgroundImage1x});
  background-repeat: no-repeat;

  @media ${({ theme }) => theme.breakpoints.xl} {
    top: 0;
    background-size: contain;
    background-image: url(${backgroundImage2x});
  }
`;

export const PageLayout = styled.div`
  width: 100%;
  margin: 0 auto;
  display: grid;
  padding: ${({ theme }) => theme.spacing(10)} 0;
  max-width: 1440px;
  justify-items: center;
  grid-template-areas: 
    "heading form"
    "content form";

  @media ${({ theme }) => theme.breakpoints.md} {
    grid-template-areas: 
      "heading"
      "form"
      "content";
  }
  @media ${({ theme }) => theme.breakpoints.sm} {
    display: flex;
    padding: ${({ theme }) => theme.spacing(10)} ${({ theme }) => theme.spacing(2)};
    align-items: center;
    flex-direction: column;
  }
`;

export const Heading = styled.div`
  width: 100%;
  grid-area: heading;
  max-width: 550px;

  h1 {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    padding-bottom: ${({ theme }) => theme.spacing(5)};
  }
`;

export const Content = styled.div`
  width: max-content;
  display: flex;
  grid-area: content;

  @media ${({ theme }) => theme.breakpoints.md} {
    padding-top: ${({ theme }) => theme.spacing(7)};
  }
`;

export const RegisterFormFrame = styled(Frame)`
  width: max-content;
  height: max-content;
  grid-area: form;

  .frame-foreground {
    padding-left: ${({ theme }) => theme.spacing(5)};
    padding-right: ${({ theme }) => theme.spacing(8)};
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    max-width: 100%;

    .frame-foreground {
      padding-left: ${({ theme }) => theme.spacing(4)};
      padding-right: ${({ theme }) => theme.spacing(4)};
    }
  }
`;
