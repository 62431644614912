// @flow

import React from 'react';
import styled from 'styled-components';

import { liz } from '../../theme';
import { Stack } from '../../layout';

// TODO: Copy over text from main app
import { Tiny } from '../../../../components/text';

const FormField = ({
  label,
  labelFor,
  children,
  hasError = false,
  message,
  preserveErrorSpace = true,
  labelTextProps = {},
  LabelAdornment,
  ...props
}) => {
  const renderMessage = () => {
    if ((hasError && message) || preserveErrorSpace) {
      return (
        <Message hasError={hasError}>
          <Tiny>{message}</Tiny>
        </Message>
      );
    } else {
      return null;
    }
  };

  return (
    <Wrapper {...props} hasError={hasError}>
      {typeof label === 'string' ? (
        <label htmlFor={labelFor} style={{ display: 'flex', alignItems: 'center' }}>
          {LabelAdornment}
          <Tiny
            weight="medium"
            transform="uppercase"
            style={{ lineHeight: "16px" }}
            {...labelTextProps}>
            {label}
          </Tiny>
        </label>
      ) : null}
      {children}
      {renderMessage()}
    </Wrapper>
  );
};

const Wrapper = styled(Stack)`
  * {
    border-color: ${({ theme, hasError }) => (hasError ? theme.color.red : null)} !important;
  }
`;

const Message = styled.div`
  height: ${({ theme }) => theme.spacing(2)};
  display: flex;
  justify-content: ${({ hasError }) => (hasError ? 'flex-start' : 'flex-end')};
  * {
    color: ${({ theme, hasError }) => (hasError ? theme.color.red : theme.color.grey70)};
  }
`;

export { FormField };
