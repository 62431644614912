import React from 'react';
import { graphql } from 'gatsby';
import { INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import {
  TermsOfServiceText,
  ReferralWelcome,
  RegisterFormFixedSizeLayout,
} from '../components/register/Register.StyledComponents';
import SEO from '../components/seo';
import Layout from '../components/layout';
import { get, post } from '../utils/requestFunctions';
import { navSections } from '../components/navbar';
import { RegisterForm } from '../components/register';
import { Tiny, Small, Huge } from '../components/text';
import { usePageMetaAttributes } from '../utils/usePageMetaAttributes';
import { RegisterPageB as RegisterPage } from '../components/register';

const RegisterPageQuery = graphql`
  query RegisterPageQuery {
    content: contentfulRegisterPage {
      ...RegisterPageContent
    }
  }
`;

const MIN_PASSWORD_LENGTH = 8;
const NEW_NAMER_LOCAL_STORAGE_KEY = 'namebase:is-new-namer';

const Register = ({ data: { content }, location }) => {
  const meta = usePageMetaAttributes('register');

  const [emailParam, setEmailParam] = React.useState('');
  const [submitting, setSubmitting] = React.useState(false);
  const [referralWelcome, setReferralWelcome] = React.useState('');
  const [fromUnitedStates, setFromUnitedStates] = React.useState(true);
  const [requiresDeviceWhitelist, setRequiresDeviceWhitelist] = React.useState(false);

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const email = searchParams.get('email') || searchParams.get('EMAIL');
    const referralCode = searchParams.get('utm_source');

    if (email) {
      setEmailParam(decodeURIComponent(email));
    }
    if (referralCode) {
      setReferralWelcome(referralCode);
    }

    get(`https://ipinfo.io/json`).then(({ country }) => setFromUnitedStates(country === 'US'));
  }, []);

  const register = (email, password) => {
    setSubmitting(true);

    post('/auth/local/register', {
      email,
      password,
    }).then(({ success, code, segmentUuid, segmentEmail, message }) => {
      if (segmentUuid && segmentEmail) {
        window.analytics.identify(segmentUuid, { email: segmentEmail });
      }
      if (success) {
        window.location = '/welcome';
      } else if (code === 'UNRECOGNIZED_IP_ADDRESS') {
        // This case happens in prod. This flag is checked by <LoginPage /> later,
        // and will redirect the new user to the /welcome page after they've confirmed
        // the sign in attempt and logged in
        window.localStorage.setItem(NEW_NAMER_LOCAL_STORAGE_KEY, true);

        setSubmitting(false);
        setRequiresDeviceWhitelist(true);
      } else {
        setSubmitting(false);
        window.alert(message);
      }
    });
  };

  return (
    <Layout noFooter minimalNavbar>
      <ReferralWelcome
        css={{ display: Boolean(referralWelcome) && !requiresDeviceWhitelist ? 'block' : 'none' }}>
        {content.referralWelcomeMessage.replace('{{ code }}', referralWelcome)}
      </ReferralWelcome>
      <SEO {...meta} />
      {requiresDeviceWhitelist ? (
        <RegisterFormFixedSizeLayout>
          <Huge as="h1" weight="medium" align="center">
            {content.deviceWhitelistHeading}
          </Huge>
          <Small
            as="h2"
            align="center"
            weight="medium"
            colored="grey70"
            css={css`
              margin-top: ${({ theme }) => theme.spacing(0.5)};
            `}>
            {content.deviceWhitelistContent}
          </Small>
        </RegisterFormFixedSizeLayout>
      ) : (
        <RegisterPage copy={content}>
          <RegisterForm
            submitting={submitting}
            handleSubmit={register}
            initialEmail={emailParam}
            minPasswordLength={MIN_PASSWORD_LENGTH}>
            <TermsOfServiceText>
              {documentToReactComponents(
                fromUnitedStates ? content.unitedStatesToS.json : content.worldwideToS.json
              )}
            </TermsOfServiceText>
          </RegisterForm>
        </RegisterPage>
      )}
    </Layout>
  );
};

export { RegisterPageQuery };
export default Register;
