import React from 'react';
import { css } from 'styled-components';

import {
  Heading,
  Content,
  PageLayout,
  RegisterFormFrame,
  PageBackgroundImage,
  RegisterFormSizeLayout,
} from './Register.StyledComponents';
import { Grid } from '../grid';
import { ReelItem } from '../LandingPageV2/2-TheCommunity/TheCommunity.StyledComponents';
import { Huge, DisplaySmall } from '../text';
import { TweetEmbedLookalike } from '../LandingPageV2/2-TheCommunity/components/TweetEmbedLookalike';

const contentCss = css`
  margin-top: ${({ theme }) => theme.spacing(5)};

  & > * + * {
    margin-left: ${({ theme }) => theme.spacing(5)};
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

const RegisterPageB = ({ copy, children }) => (
  <React.Fragment>
    <PageBackgroundImage />
    <PageLayout>
      <Heading>
        <DisplaySmall as="h1" align="center" weight="bold" colored="nbPrimary">
          {copy.heading1}
        </DisplaySmall>
        <Huge as="h2" align="center">
          {copy.heading2}
        </Huge>
      </Heading>
      <Content css={contentCss}>
        {copy.tweets.map((tweet, index) => (
          <ReelItem key={tweet.id}>
            <TweetEmbedLookalike {...tweet} />
          </ReelItem>
        ))}
      </Content>
      <RegisterFormFrame>
        <RegisterFormSizeLayout>{children}</RegisterFormSizeLayout>
      </RegisterFormFrame>
    </PageLayout>
  </React.Fragment>
);

export { RegisterPageB };
