import React from 'react';

// type TOptions = {
//   validateOnChange?: boolean,
//   validationErrorMessage?: string,
//   validateInitial?: boolean,
// };

// type TReturnTuple<TState> = [
//   TState, // state value
//   ((TState => TState) | TState) => void, // state setter
//   () => boolean, // run validate function
//   boolean, // is valid
//   string, // validation error message
//   (valid: boolean, message?: string) => void // set validation error function
// ];

const DEFAULT_OPTIONS/*: TOptions */ = {
  validateOnChange: false,
  validationErrorMessage: '',
  validateInitial: false,
};

function useValidatedState/*<TState>*/(
  initialState/*: TState*/,
  validator/*:(state: TState) => boolean*/,
  options/*?: TOptions*/ = {},
)/*: TReturnTuple<TState>*/ {
  const internalUseOptions = { ...DEFAULT_OPTIONS, ...options };
  const { validateOnChange, validationErrorMessage, validateInitial } = internalUseOptions;

  const [state, setState] = React.useState/*<TState>*/(initialState);

  const [isValid, setIsValid] = React.useState/*<boolean>*/(validateInitial ? false : true);

  const [validationError, setValidationError] = React.useState/*<string>*/(
    validateInitial && validationErrorMessage ? validationErrorMessage : ''
  );

  const setValid = (valid/*: boolean*/, message/*?: string*/ = '') => {
    setIsValid(valid);
    setValidationError(message);
  };

  const runValidate = React.useCallback(() => {
    const isCurrentValid = validator(state);
    setValid(isCurrentValid, isCurrentValid ? '' : validationErrorMessage);
    return isCurrentValid;
  }, [state]);

  React.useEffect(() => {
    if (validateOnChange) runValidate();
  }, [state]);

  return [state, setState, runValidate, isValid, validationError, setValid];
}

export { useValidatedState };
