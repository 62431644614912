import * as React from 'react';
import styled from 'styled-components';
import { liz } from '../theme/liz';

const Stack = React.forwardRef(
  ({ spacing = liz.spacing(1), listSemantics = false, children, ...props }, childrenRef) => {
    const childrenProps = {};
    const childProps = {};

    if (listSemantics) {
      childrenProps['role'] = 'list';
      childProps['role'] = 'listitem';
    }

    return (
      <Children {...props} {...childrenProps} ref={childrenRef} spacing={spacing}>
        {React.Children.map(children, child =>
          child ? React.cloneElement(child, childProps) : null
        )}
      </Children>
    );
  }
);

const Children = styled.div.attrs(props => ({
  as: props.component ? props.component : 'div',
}))`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  & > * + * {
    margin-top: ${({ spacing }) => spacing};
  }
`;

export { Stack };
