import React from 'react';
import { css } from 'styled-components';

import { Grid } from '../grid';
import { RegisterFormSizeLayout } from './Register.StyledComponents';

const RegisterPageA = ({ copy, children }) => (
  <Grid
    css={css`
      @media ${({ theme }) => theme.breakpoints.md} {
        padding-top: ${({ theme }) => theme.spacing(2)};
      }
    `}>
    <RegisterFormSizeLayout>{children}</RegisterFormSizeLayout>
  </Grid>
);

export { RegisterPageA };
