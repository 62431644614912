import React from 'react';
import styled from 'styled-components';

import { theme } from '../../theme';
import { Stack } from '../../packages/NamebaseUi/layout';
import { Button } from '../Button';
import { Small, Huge } from '../text';
import { useValidatedState } from '../../utils/useValidatedState';
import { Form, FormField, TextInput } from '../../packages/NamebaseUi/Form';
import { GithubButton, RegisterTextInput, DashedLine, Divider } from './Register.StyledComponents';

const RegisterForm = ({
  children,
  submitting,
  handleSubmit,
  initialEmail = '',
  minPasswordLength,
}) => {
  const [
    email,
    setEmail,
    validateEmail,
    isEmailValid,
    emailValidationError,
    setEmailValid,
  ] = useValidatedState('', emailValidator, {
    validationErrorMessage: 'Please enter a valid email address.',
  });

  const [
    password,
    setPassword,
    validatePassword,
    isPasswordValid,
    passwordValidationError,
    setPasswordValid,
  ] = useValidatedState('', passwordValidator(minPasswordLength), {
    validationErrorMessage: `Please enter a longer password (${minPasswordLength} characters).`,
  });

  React.useEffect(() => {
    setEmail(initialEmail);
  }, [initialEmail]);

  const onSubmit = () => {
    if (validateEmail() && validatePassword()) handleSubmit(email, password);
  };

  return (
    <Stack spacing={theme.spacing(2)}>
      <Stack spacing={theme.spacing(0.5)} css={{ paddingBottom: theme.spacing(3) }}>
        <Huge as="h3" weight="medium" align="center">
          Sign up
        </Huge>
        <Small weight="medium" colored="grey60" align="center">
          Already have an account?{' '}
          <a css={{ color: theme.color.nbPrimary }} href="/login">
            Log in
          </a>
        </Small>
      </Stack>
      <GithubButton href="/auth/github" />
      <div css={{ paddingTop: theme.spacing(1) }}>
        <Divider>
          <DashedLine />
          <Small
            align="center"
            weight="medium"
            colored="grey60"
            css={{
              maxWidth: 'max-content',
              marginLeft: theme.spacing(1),
              marginRight: theme.spacing(1),
            }}>
            Sign up with email
          </Small>
          <DashedLine />
        </Divider>
        <Form handleSubmit={onSubmit}>
          <FormField
            message={emailValidationError}
            hasError={!isEmailValid}
            labelFor="register-email"
            preserveErrorSpace={false}>
            <RegisterTextInput
              id="register-email"
              fat
              autoFocus
              value={email}
              onblur={() => setTimeout(validateEmail, 100)}
              onChange={setEmail}
              placeholder="Email"
              autoComplete="email"
            />
          </FormField>
          <FormField
            message={passwordValidationError}
            hasError={!isPasswordValid}
            labelFor="register-password"
            preserveErrorSpace={true}>
            <RegisterTextInput
              id="register-password"
              fat
              password
              value={password}
              onblur={() => setTimeout(validatePassword, 100)}
              onChange={setPassword}
              placeholder="Password"
              autoComplete="new-password"
            />
          </FormField>
          {children}
          <Button
            disabled={submitting}
            type="submit"
            size="large"
            css={{ cursor: submitting ? 'not-allowed' : 'auto' }}>
            Sign Up
          </Button>
        </Form>
      </div>
    </Stack>
  );
};

// http://emailregex.com/
const emailValidator = email => {
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (email.length === 0 || (email.length > 0 && !emailRegex.test(email))) return false;
  return true;
};

const passwordValidator = minPasswordLength => {
  return password => {
    if (password.length < minPasswordLength) return false;
    return true;
  }
}

export { RegisterForm };
